const BackwardArrow = ({ width, height, color, ...props }) => (
  <svg
    width={width || '21'}
    height={height || '20'}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.75 10C0.75 4.61547 5.11547 0.25 10.5 0.25C15.8845 0.25 20.25 4.61547 20.25 10C20.25 15.3845 15.8845 19.75 10.5 19.75C5.11547 19.75 0.75 15.3845 0.75 10ZM10.718 14.2825C10.7879 14.2131 10.8435 14.1307 10.8816 14.0398C10.9196 13.949 10.9394 13.8515 10.9398 13.753C10.9402 13.6545 10.9212 13.5569 10.8838 13.4657C10.8465 13.3745 10.7916 13.2916 10.7222 13.2217L8.26969 10.75H14.5312C14.7302 10.75 14.9209 10.671 15.0616 10.5303C15.2022 10.3897 15.2812 10.1989 15.2812 10C15.2812 9.80109 15.2022 9.61032 15.0616 9.46967C14.9209 9.32902 14.7302 9.25 14.5312 9.25H8.26969L10.7222 6.77828C10.7916 6.70829 10.8465 6.62532 10.8838 6.53411C10.9211 6.4429 10.9401 6.34523 10.9396 6.24669C10.9392 6.14814 10.9194 6.05064 10.8812 5.95977C10.8431 5.86889 10.7875 5.78641 10.7175 5.71703C10.6475 5.64766 10.5645 5.59275 10.4733 5.55544C10.3821 5.51813 10.2845 5.49915 10.1859 5.49958C9.98688 5.50046 9.79636 5.58037 9.65625 5.72172L5.93484 9.47172C5.79544 9.6122 5.71721 9.80209 5.71721 10C5.71721 10.1979 5.79544 10.3878 5.93484 10.5283L9.65625 14.2783C9.72564 14.3484 9.80816 14.4041 9.8991 14.4422C9.99004 14.4804 10.0876 14.5002 10.1862 14.5006C10.2849 14.501 10.3826 14.4819 10.4738 14.4445C10.5651 14.4071 10.648 14.352 10.718 14.2825Z"
      fill={color || '#FC6244'}
    />
  </svg>
);

export default BackwardArrow;
