const Edit = ({ width, height, color, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '24'}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.4816 2.49609C22.4131 2.42083 22.33 2.36024 22.2374 2.31797C22.1449 2.27571 22.0447 2.25266 21.943 2.2502C21.8412 2.24775 21.74 2.26595 21.6455 2.3037C21.551 2.34145 21.4652 2.39797 21.3931 2.46984L20.8133 3.04687C20.743 3.11719 20.7035 3.21254 20.7035 3.31195C20.7035 3.41137 20.743 3.50671 20.8133 3.57703L21.3448 4.10765C21.3797 4.14266 21.4211 4.17044 21.4667 4.1894C21.5123 4.20835 21.5612 4.21811 21.6106 4.21811C21.66 4.21811 21.7089 4.20835 21.7545 4.1894C21.8001 4.17044 21.8416 4.14266 21.8764 4.10765L22.4417 3.54515C22.7277 3.25969 22.7544 2.79469 22.4816 2.49609ZM19.6409 4.21875L11.1791 12.6656C11.1278 12.7167 11.0905 12.7802 11.0708 12.8498L10.6794 14.0156C10.67 14.0473 10.6693 14.0808 10.6774 14.1128C10.6856 14.1448 10.7022 14.174 10.7255 14.1973C10.7488 14.2206 10.778 14.2372 10.81 14.2454C10.842 14.2535 10.8756 14.2528 10.9072 14.2434L12.072 13.852C12.1417 13.8323 12.2052 13.7951 12.2562 13.7437L20.7031 5.28094C20.7813 5.20195 20.8251 5.09533 20.8251 4.98422C20.8251 4.87311 20.7813 4.76648 20.7031 4.6875L20.2367 4.21875C20.1576 4.13989 20.0505 4.0956 19.9388 4.0956C19.8271 4.0956 19.72 4.13989 19.6409 4.21875Z"
      fill={color || '#FC6244'}
    />
    <path
      d="M19.0316 9.07781L13.318 14.8027C13.0971 15.024 12.8257 15.1883 12.5272 15.2812L11.3131 15.6877C11.025 15.769 10.7204 15.7721 10.4307 15.6965C10.141 15.621 9.87665 15.4696 9.66494 15.2579C9.45324 15.0462 9.30181 14.7818 9.22627 14.4921C9.15072 14.2024 9.15379 13.8978 9.23516 13.6097L9.64156 12.3956C9.73425 12.0972 9.89819 11.8258 10.1192 11.6048L15.8441 5.89031C15.8965 5.8379 15.9323 5.77109 15.9468 5.69835C15.9613 5.62562 15.9539 5.55021 15.9256 5.48167C15.8972 5.41313 15.8492 5.35454 15.7875 5.3133C15.7259 5.27206 15.6534 5.25003 15.5792 5.25H5.79688C5.10068 5.25 4.433 5.52656 3.94072 6.01884C3.44844 6.51113 3.17188 7.17881 3.17188 7.875V19.125C3.17188 19.8212 3.44844 20.4889 3.94072 20.9812C4.433 21.4734 5.10068 21.75 5.79688 21.75H17.0469C17.7431 21.75 18.4107 21.4734 18.903 20.9812C19.3953 20.4889 19.6719 19.8212 19.6719 19.125V9.34266C19.6718 9.26849 19.6498 9.19599 19.6086 9.13434C19.5673 9.07269 19.5087 9.02465 19.4402 8.9963C19.3717 8.96795 19.2963 8.96057 19.2235 8.97507C19.1508 8.98958 19.084 9.02534 19.0316 9.07781Z"
      fill={color || '#FC6244'}
    />
  </svg>
);

export default Edit;
