const Discover = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4985_2941)">
      <path
        d="M11.4673 11.4694L11.4672 11.4694L11.4642 11.4726C11.3968 11.543 11.3438 11.6257 11.3082 11.7163C11.2655 11.8179 11.2458 11.9277 11.2504 12.0378C11.2551 12.1493 11.2846 12.2583 11.3368 12.357C11.3889 12.4556 11.4625 12.5414 11.552 12.608L11.7012 12.4075C11.7609 12.4519 11.8297 12.4824 11.9027 12.4969C11.9757 12.5113 12.051 12.5093 12.1231 12.4909C12.1951 12.4726 12.2622 12.4384 12.3194 12.3908C12.3766 12.3433 12.4225 12.2836 12.4537 12.216C12.4849 12.1485 12.5006 12.0749 12.4998 12.0005L12.7498 12.0019C12.7498 12.0012 12.7498 12.0005 12.7498 11.9998C12.7502 11.8515 12.7067 11.7065 12.6248 11.583C12.5424 11.4588 12.4249 11.362 12.2874 11.3049L12.1915 11.5358L12.2874 11.3049C12.1498 11.2478 11.9983 11.2329 11.8522 11.2622C11.7062 11.2915 11.5722 11.3637 11.4673 11.4694ZM12.8534 12.8542L9.43458 14.5657L11.1461 11.1468L14.5649 9.43531L12.8534 12.8542Z"
        fill={color || '#FC6244'}
        stroke={color || '#FC6244'}
        strokeWidth="0.5"
      />
      <path
        d="M7.77143 16.995L7.77143 16.9951L7.77649 16.995C7.89353 16.9943 8.00877 16.9662 8.11301 16.913C8.11334 16.9129 8.11366 16.9127 8.11399 16.9125L13.7504 14.0843C13.8961 14.0137 14.0137 13.8961 14.0843 13.7504L16.9084 8.1222C16.9945 7.98075 17.0314 7.81466 17.0131 7.64983C16.9944 7.48045 16.9185 7.3225 16.798 7.20199C16.6775 7.08149 16.5196 7.00562 16.3502 6.98687C16.1855 6.96865 16.0195 7.00549 15.8782 7.09141L10.2499 9.89556C10.104 9.96617 9.98625 10.0839 9.9156 10.2298L7.08639 15.8882L7.08599 15.889C7.01308 16.0361 6.99046 16.2031 7.02162 16.3644C7.05226 16.5229 7.1332 16.6672 7.25231 16.7759C7.39021 16.9136 7.57639 16.9922 7.77143 16.995ZM19.4697 11.4697C19.329 11.6103 19.25 11.8011 19.25 12C19.25 12.1989 19.329 12.3897 19.4697 12.5304C19.6103 12.671 19.8011 12.75 20 12.75H20.7321C20.5584 14.8058 19.6632 16.7375 18.2004 18.2004C16.7375 19.6633 14.8058 20.5584 12.75 20.7321V20C12.75 19.8011 12.671 19.6103 12.5303 19.4697C12.3897 19.329 12.1989 19.25 12 19.25C11.8011 19.25 11.6103 19.329 11.4697 19.4697C11.329 19.6103 11.25 19.8011 11.25 20V20.7314C9.19888 20.5521 7.27333 19.6547 5.8156 18.1927C4.35769 16.7304 3.46588 14.802 3.29285 12.75H3.99999C4.19891 12.75 4.38967 12.671 4.53032 12.5304C4.67098 12.3897 4.74999 12.1989 4.74999 12C4.74999 11.8011 4.67098 11.6103 4.53032 11.4697C4.38967 11.329 4.19891 11.25 3.99999 11.25H3.29361C3.47218 9.20281 4.36624 7.28043 5.82332 5.82335C7.2804 4.36627 9.20278 3.47221 11.25 3.29364V4.00002C11.25 4.19894 11.329 4.3897 11.4697 4.53035C11.6103 4.67101 11.8011 4.75002 12 4.75002C12.1989 4.75002 12.3897 4.67101 12.5303 4.53035C12.671 4.3897 12.75 4.19894 12.75 4.00002V3.29288C14.8019 3.46591 16.7304 4.35772 18.1926 5.81563C19.6547 7.27336 20.5521 9.19891 20.7313 11.25H20C19.8011 11.25 19.6103 11.329 19.4697 11.4697Z"
        fill={color || '#FC6244'}
        stroke={color || '#FC6244'}
        strokeWidth="0.5"
      />
      <path
        d="M5.74999 2.6456C7.60005 1.40943 9.77512 0.749634 12.0002 0.749634C14.9838 0.749634 17.8453 1.9349 19.9551 4.04468C22.0649 6.15447 23.2502 9.01595 23.2502 11.9996C23.2502 14.2247 22.5904 16.3997 21.3542 18.2498C20.118 20.0999 18.361 21.5418 16.3053 22.3933C14.2497 23.2448 11.9877 23.4676 9.80539 23.0335C7.6231 22.5994 5.61855 21.5279 4.04521 19.9546C2.47187 18.3812 1.40041 16.3767 0.966323 14.1944C0.53224 12.0121 0.755027 9.75011 1.60651 7.69444C2.458 5.63878 3.89994 3.88177 5.74999 2.6456ZM6.30556 20.5222C7.99117 21.6485 9.9729 22.2496 12.0002 22.2496C14.7186 22.2496 17.3258 21.1697 19.248 19.2475C21.1703 17.3252 22.2502 14.7181 22.2502 11.9996C22.2502 9.97237 21.649 7.99064 20.5227 6.30504C19.3964 4.61943 17.7956 3.30567 15.9227 2.52987C14.0497 1.75407 11.9888 1.55109 10.0005 1.94658C8.01218 2.34208 6.1858 3.3183 4.75231 4.75179C3.31883 6.18528 2.34261 8.01165 1.94711 9.99996C1.55161 11.9883 1.75459 14.0492 2.53039 15.9221C3.30619 17.7951 4.61996 19.3959 6.30556 20.5222Z"
        fill={color || '#FC6244'}
        stroke={color || '#FC6244'}
        strokeWidth="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_4985_2941">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Discover;
