const House = ({ width, height, color, ...props }) => (
  <svg
    width={width || '120'}
    height={height || '120'}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M112.5 58.9875L105 32.7375C104.773 31.9264 104.28 31.2152 103.599 30.7186C102.919 30.222 102.092 29.9689 101.25 30H93.7499V18.75C93.7499 17.7554 93.3548 16.8016 92.6515 16.0984C91.9483 15.3951 90.9944 15 89.9999 15H74.9999C74.0053 15 73.0515 15.3951 72.3482 16.0984C71.645 16.8016 71.2499 17.7554 71.2499 18.75V30H18.7499C17.9082 29.9689 17.0806 30.222 16.4003 30.7186C15.72 31.2152 15.2267 31.9264 14.9999 32.7375L7.49988 58.9875C7.34297 59.5409 7.31548 60.123 7.41952 60.6887C7.52356 61.2544 7.75635 61.7886 8.09988 62.25C8.46407 62.7366 8.94107 63.1273 9.48981 63.3886C10.0385 63.6499 10.6426 63.774 11.2499 63.75H14.9999V97.5C14.9999 98.4946 15.395 99.4484 16.0982 100.152C16.8015 100.855 17.7553 101.25 18.7499 101.25H101.25C102.244 101.25 103.198 100.855 103.902 100.152C104.605 99.4484 105 98.4946 105 97.5V63.75H108.75C109.332 63.75 109.906 63.6145 110.427 63.3541C110.948 63.0937 111.401 62.7157 111.75 62.25C112.119 61.8014 112.379 61.2733 112.509 60.7071C112.639 60.1409 112.636 59.5522 112.5 58.9875ZM78.7499 22.5H86.2499V30H78.7499V22.5ZM21.5624 37.5H98.4374L103.762 56.25H16.2374L21.5624 37.5ZM59.9999 93.75H37.4999V75H59.9999V93.75ZM97.4999 93.75H67.4999V71.25C67.4999 70.2554 67.1048 69.3016 66.4015 68.5983C65.6983 67.8951 64.7444 67.5 63.7499 67.5H33.7499C32.7553 67.5 31.8015 67.8951 31.0982 68.5983C30.395 69.3016 29.9999 70.2554 29.9999 71.25V93.75H22.4999V63.75H97.4999V93.75Z"
      fill={color || '#FBF9F5'}
    />
    <path
      d="M75 90H90C90.9946 90 91.9484 89.6049 92.6517 88.9017C93.3549 88.1984 93.75 87.2446 93.75 86.25V71.25C93.75 70.2554 93.3549 69.3016 92.6517 68.5983C91.9484 67.8951 90.9946 67.5 90 67.5H75C74.0054 67.5 73.0516 67.8951 72.3483 68.5983C71.6451 69.3016 71.25 70.2554 71.25 71.25V86.25C71.25 87.2446 71.6451 88.1984 72.3483 88.9017C73.0516 89.6049 74.0054 90 75 90ZM78.75 75H86.25V82.5H78.75V75Z"
      fill={color || '#FBF9F5'}
    />
  </svg>
);

export default House;
