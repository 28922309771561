const Basket = ({ width, height, color, ...props }) => (
  <svg
    width={width || '120'}
    height={height || '120'}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M102.5 46.25H98.75V30C98.75 22.4 92.6 16.25 85 16.25H78.5C77.9 13.4 75.5 11.25 72.5 11.25H47.5C44.5 11.25 42.1 13.4 41.5 16.25H35C27.4 16.25 21.25 22.4 21.25 30V46.25H17.5C14.05 46.25 11.25 49.05 11.25 52.5V62.5C11.25 65.65 13.65 68.15 16.65 68.6L20.15 96.7C21 103.55 26.85 108.75 33.8 108.75H86.15C93.05 108.75 98.95 103.55 99.8 96.7L103.3 68.6C106.35 68.2 108.7 65.65 108.7 62.5V52.5C108.7 49.05 105.9 46.25 102.45 46.25H102.5ZM94.2 81.25H81.75L83.3 68.75H95.75L94.2 81.25ZM24.25 68.75H36.7L38.25 81.25H25.8L24.25 68.75ZM44.25 68.75H56.25V81.25H45.8L44.25 68.75ZM56.25 88.75V101.25H48.3L46.75 88.75H56.25ZM63.75 88.75H73.25L71.7 101.25H63.75V88.75ZM63.75 81.25V68.75H75.75L74.2 81.25H63.75ZM48.75 18.75H71.25V21.25H48.75V18.75ZM28.75 30C28.75 26.55 31.55 23.75 35 23.75H41.5C42.1 26.6 44.5 28.75 47.5 28.75H72.5C75.5 28.75 77.9 26.6 78.5 23.75H85C88.45 23.75 91.25 26.55 91.25 30V46.25H28.75V30ZM18.75 53.75H101.25V61.25H18.75V53.75ZM27.65 95.75L26.75 88.75H39.2L40.75 101.25H33.85C30.7 101.25 28.05 98.9 27.65 95.75ZM86.2 101.25H79.3L80.85 88.75H93.3L92.4 95.8C92 98.9 89.35 101.25 86.2 101.25Z"
      fill={color || '#1A202B'}
    />
  </svg>
);

export default Basket;
