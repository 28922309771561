const Bill = ({ width, height, color, ...props }) => (
  <svg
    width={width || '120'}
    height={height || '120'}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M94.7613 7.4993H25.2363C23.5157 7.4993 21.8656 8.1828 20.6489 9.39945C19.4323 10.6161 18.7488 12.2662 18.7488 13.9868V108.749C18.7506 109.428 18.9364 110.093 19.2864 110.674C19.6364 111.255 20.1375 111.73 20.7363 112.049C21.3227 112.35 21.9781 112.492 22.6364 112.459C23.2948 112.426 23.9328 112.22 24.4863 111.862L33.1863 106.087L38.5113 111.412C39.1261 112.02 39.9324 112.397 40.7935 112.479C41.6546 112.561 42.5176 112.343 43.2363 111.862L51.9363 106.087L57.2613 111.412C57.6099 111.763 58.0246 112.042 58.4816 112.233C58.9386 112.423 59.4287 112.521 59.9238 112.521C60.4188 112.521 60.909 112.423 61.3659 112.233C61.8229 112.042 62.2377 111.763 62.5863 111.412L67.9113 106.087L76.6113 111.862C77.2426 112.286 77.9881 112.509 78.7488 112.499C79.2423 112.502 79.7315 112.408 80.1884 112.221C80.6453 112.034 81.0609 111.759 81.4113 111.412L86.7363 106.087L95.4363 111.862C95.9897 112.22 96.6278 112.426 97.2861 112.459C97.9445 112.492 98.5999 112.35 99.1863 112.049C99.7991 111.741 100.315 111.27 100.679 110.688C101.043 110.106 101.24 109.435 101.249 108.749V13.9868C101.249 12.2662 100.565 10.6161 99.3486 9.39945C98.132 8.1828 96.4819 7.4993 94.7613 7.4993ZM93.7488 101.737L88.3113 97.9868C87.5926 97.5053 86.7296 97.2871 85.8685 97.3691C85.0074 97.4512 84.2011 97.8283 83.5863 98.4368L78.2613 103.762L69.5613 97.9868C68.935 97.6294 68.2189 97.4601 67.4988 97.4993C67.0053 97.4964 66.516 97.591 66.0591 97.7777C65.6022 97.9643 65.1867 98.2392 64.8363 98.5868L59.9988 103.462L55.1613 98.5868C54.5464 97.9783 53.7402 97.6012 52.879 97.5191C52.0179 97.4371 51.1549 97.6553 50.4363 98.1368L41.7363 103.912L36.4113 98.5868C35.7964 97.9783 34.9902 97.6012 34.129 97.5191C33.2679 97.4371 32.4049 97.6553 31.6863 98.1368L26.2488 101.737V14.9993H93.7488V101.737Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M40.3113 44.9987H79.6863C81.4237 44.9889 83.0872 44.2943 84.3158 43.0657C85.5444 41.8372 86.2389 40.1737 86.2488 38.4362V29.0612C86.2389 27.3238 85.5444 25.6603 84.3158 24.4317C83.0872 23.2031 81.4237 22.5086 79.6863 22.4987H40.3113C38.5738 22.5086 36.9103 23.2031 35.6818 24.4317C34.4532 25.6603 33.7586 27.3238 33.7488 29.0612V38.4362C33.7586 40.1737 34.4532 41.8372 35.6818 43.0657C36.9103 44.2943 38.5738 44.9889 40.3113 44.9987ZM41.2488 29.9987H78.7488V37.4987H41.2488V29.9987Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M37.4988 63.7487H59.9988C60.9933 63.7487 61.9472 63.3536 62.6504 62.6504C63.3537 61.9471 63.7488 60.9933 63.7488 59.9987C63.7488 59.0042 63.3537 58.0503 62.6504 57.3471C61.9472 56.6438 60.9933 56.2487 59.9988 56.2487H37.4988C36.5042 56.2487 35.5504 56.6438 34.8471 57.3471C34.1439 58.0503 33.7488 59.0042 33.7488 59.9987C33.7488 60.9933 34.1439 61.9471 34.8471 62.6504C35.5504 63.3536 36.5042 63.7487 37.4988 63.7487Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M82.4988 56.2487H74.9988C74.0042 56.2487 73.0504 56.6438 72.3471 57.3471C71.6439 58.0503 71.2488 59.0042 71.2488 59.9987C71.2488 60.9933 71.6439 61.9471 72.3471 62.6504C73.0504 63.3536 74.0042 63.7487 74.9988 63.7487H82.4988C83.4933 63.7487 84.4472 63.3536 85.1504 62.6504C85.8537 61.9471 86.2488 60.9933 86.2488 59.9987C86.2488 59.0042 85.8537 58.0503 85.1504 57.3471C84.4472 56.6438 83.4933 56.2487 82.4988 56.2487Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M37.4988 74.9987H59.9988C60.9933 74.9987 61.9472 74.6036 62.6504 73.9004C63.3537 73.1971 63.7488 72.2433 63.7488 71.2487C63.7488 70.2542 63.3537 69.3003 62.6504 68.5971C61.9472 67.8938 60.9933 67.4987 59.9988 67.4987H37.4988C36.5042 67.4987 35.5504 67.8938 34.8471 68.5971C34.1439 69.3003 33.7488 70.2542 33.7488 71.2487C33.7488 72.2433 34.1439 73.1971 34.8471 73.9004C35.5504 74.6036 36.5042 74.9987 37.4988 74.9987Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M82.4988 67.4987H74.9988C74.0042 67.4987 73.0504 67.8938 72.3471 68.5971C71.6439 69.3003 71.2488 70.2542 71.2488 71.2487C71.2488 72.2433 71.6439 73.1971 72.3471 73.9004C73.0504 74.6036 74.0042 74.9987 74.9988 74.9987H82.4988C83.4933 74.9987 84.4472 74.6036 85.1504 73.9004C85.8537 73.1971 86.2488 72.2433 86.2488 71.2487C86.2488 70.2542 85.8537 69.3003 85.1504 68.5971C84.4472 67.8938 83.4933 67.4987 82.4988 67.4987Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M37.4988 86.2487H59.9988C60.9933 86.2487 61.9472 85.8536 62.6504 85.1504C63.3537 84.4471 63.7488 83.4933 63.7488 82.4987C63.7488 81.5042 63.3537 80.5503 62.6504 79.8471C61.9472 79.1438 60.9933 78.7487 59.9988 78.7487H37.4988C36.5042 78.7487 35.5504 79.1438 34.8471 79.8471C34.1439 80.5503 33.7488 81.5042 33.7488 82.4987C33.7488 83.4933 34.1439 84.4471 34.8471 85.1504C35.5504 85.8536 36.5042 86.2487 37.4988 86.2487Z"
      fill={color || '#1A202B'}
    />
    <path
      d="M82.4988 78.7487H74.9988C74.0042 78.7487 73.0504 79.1438 72.3471 79.8471C71.6439 80.5503 71.2488 81.5042 71.2488 82.4987C71.2488 83.4933 71.6439 84.4471 72.3471 85.1504C73.0504 85.8536 74.0042 86.2487 74.9988 86.2487H82.4988C83.4933 86.2487 84.4472 85.8536 85.1504 85.1504C85.8537 84.4471 86.2488 83.4933 86.2488 82.4987C86.2488 81.5042 85.8537 80.5503 85.1504 79.8471C84.4472 79.1438 83.4933 78.7487 82.4988 78.7487Z"
      fill={color || '#1A202B'}
    />
  </svg>
);

export default Bill;
