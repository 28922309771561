const Hide = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.53003 2.47C3.38785 2.33752 3.19981 2.2654 3.00551 2.26882C2.81121 2.27225 2.62582 2.35096 2.48841 2.48838C2.35099 2.62579 2.27228 2.81118 2.26885 3.00548C2.26543 3.19978 2.33755 3.38782 2.47003 3.53L20.47 21.53C20.5387 21.6037 20.6215 21.6628 20.7135 21.7038C20.8055 21.7448 20.9048 21.7668 21.0055 21.7686C21.1062 21.7704 21.2062 21.7518 21.2996 21.7141C21.393 21.6764 21.4778 21.6203 21.5491 21.549C21.6203 21.4778 21.6764 21.393 21.7142 21.2996C21.7519 21.2062 21.7704 21.1062 21.7686 21.0055C21.7668 20.9048 21.7448 20.8055 21.7038 20.7135C21.6628 20.6215 21.6037 20.5387 21.53 20.47L3.53003 2.47ZM22.676 12.553C22.137 14.1705 21.2373 15.6443 20.045 16.863L16.946 13.764C17.2804 12.8279 17.3421 11.8162 17.124 10.8464C16.906 9.8766 16.4171 8.98864 15.7142 8.28578C15.0114 7.58292 14.1234 7.09404 13.1536 6.87598C12.1839 6.65792 11.1721 6.71966 10.236 7.054L7.75903 4.577C9.10605 4.02896 10.5468 3.74808 12.001 3.75C16.971 3.75 21.186 6.973 22.676 11.44C22.796 11.802 22.796 12.192 22.676 12.553Z"
      fill={color || '#FC6244'}
    />
    <path
      d="M15.75 12C15.75 12.18 15.737 12.357 15.713 12.53L11.469 8.28699C12.001 8.2109 12.5431 8.25003 13.0587 8.40174C13.5742 8.55344 14.0511 8.81418 14.4571 9.16629C14.863 9.5184 15.1886 9.95365 15.4117 10.4426C15.6347 10.9315 15.7501 11.4626 15.75 12ZM12.53 15.713L8.28702 11.469C8.20461 12.0454 8.25755 12.633 8.44164 13.1854C8.62573 13.7378 8.93591 14.2397 9.34762 14.6514C9.75932 15.0631 10.2612 15.3733 10.8136 15.5574C11.366 15.7415 11.9536 15.7944 12.53 15.712V15.713Z"
      fill={color || '#FC6244'}
    />
    <path
      d="M6.75001 12C6.75001 11.381 6.85701 10.787 7.05401 10.236L3.95401 7.13599C2.76211 8.35483 1.8628 9.82861 1.32401 11.446C1.20401 11.808 1.20401 12.198 1.32401 12.56C2.81301 17.027 7.02801 20.25 11.999 20.25C13.499 20.25 14.932 19.956 16.241 19.423L13.764 16.946C12.9717 17.2286 12.123 17.3168 11.2895 17.2029C10.456 17.0891 9.662 16.7766 8.97449 16.2917C8.28697 15.8069 7.72603 15.164 7.33891 14.4171C6.95178 13.6702 6.74981 12.8412 6.75001 12Z"
      fill={color || '#FC6244'}
    />
  </svg>
);

export default Hide;
