const MoneyBag = ({ width, height, color, ...props }) => (
  <svg
    width={width || '120'}
    height={height || '120'}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.0249 59.2875C48.796 48.7272 56.3379 39.6561 65.8499 33.0375C68.5137 31.1154 71.7151 30.081 74.9999 30.081C78.2847 30.081 81.4861 31.1154 84.1499 33.0375C93.6619 39.6561 101.204 48.7272 105.975 59.2875C115.612 80.85 98.8874 105 74.9999 105C70.5529 105.016 66.1467 104.153 62.0344 102.46C57.9221 100.768 54.1848 98.279 51.0374 95.1375"
      stroke={color || '#FBF9F5'}
      strokeWidth="7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.5998 28.4625H83.3998L86.2498 18.75C86.4166 18.1729 86.4149 17.5602 86.245 16.9841C86.075 16.408 85.7439 15.8925 85.2907 15.4983C84.8374 15.1042 84.2809 14.8479 83.6868 14.7596C83.0926 14.6713 82.4856 14.7547 81.9373 15L76.0498 16.7625C75.3625 16.9507 74.6372 16.9507 73.9498 16.7625L68.0623 15C67.514 14.7547 66.907 14.6713 66.3129 14.7596C65.7188 14.8479 65.1623 15.1042 64.709 15.4983C64.2558 15.8925 63.9246 16.408 63.7547 16.9841C63.5847 17.5602 63.5831 18.1729 63.7498 18.75L66.5998 28.4625Z"
      stroke={color || '#FBF9F5'}
      strokeWidth="7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60 30H90"
      stroke={color || '#FBF9F5'}
      strokeWidth="7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7498 93.7499H13.4623C13.1704 93.7449 12.8804 93.7986 12.6097 93.908C12.339 94.0174 12.093 94.1802 11.8866 94.3866C11.6801 94.5931 11.5173 94.839 11.4079 95.1098C11.2985 95.3805 11.2448 95.6705 11.2498 95.9624V102.787C11.2448 103.079 11.2985 103.369 11.4079 103.64C11.5173 103.911 11.6801 104.157 11.8866 104.363C12.093 104.57 12.339 104.732 12.6097 104.842C12.8804 104.951 13.1704 105.005 13.4623 105H42.7873C43.0793 105.005 43.3693 104.951 43.64 104.842C43.9107 104.732 44.1567 104.57 44.3631 104.363C44.5696 104.157 44.7324 103.911 44.8418 103.64C44.9512 103.369 45.0049 103.079 44.9998 102.787V95.9624C45.0049 95.6705 44.9512 95.3805 44.8418 95.1098C44.7324 94.839 44.5696 94.5931 44.3631 94.3866C44.1567 94.1802 43.9107 94.0174 43.64 93.908C43.3693 93.7986 43.0793 93.7449 42.7873 93.7499H44.9998"
      stroke={color || '#FBF9F5'}
      strokeWidth="7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.2875 82.5H20.9625C19.7406 82.5 18.75 83.4906 18.75 84.7125V91.5375C18.75 92.7595 19.7406 93.75 20.9625 93.75H50.2875C51.5094 93.75 52.5 92.7595 52.5 91.5375V84.7125C52.5 83.4906 51.5094 82.5 50.2875 82.5Z"
      stroke={color || '#FBF9F5'}
      strokeWidth="7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.875 78.75C39.1237 78.75 45 72.8738 45 65.625C45 58.3763 39.1237 52.5 31.875 52.5C24.6263 52.5 18.75 58.3763 18.75 65.625C18.75 72.8738 24.6263 78.75 31.875 78.75Z"
      stroke={color || '#FBF9F5'}
      strokeWidth="7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.0796 79H80.749V75.2677H70.7551C71.773 73.5712 72.2974 71.9056 72.6676 69.7464L72.7292 69.4688H78.2814V66.5076H73.2228L73.6854 63.8549C74.2715 60.3694 75.7212 59.2281 78.0038 59.2281C78.96 59.2281 79.9162 59.4749 80.6257 59.845V56.051C79.9779 55.8043 78.8983 55.4958 77.6953 55.4958C72.8526 55.4958 70.6009 58.3953 69.6755 63.4848L69.1512 66.5076H65.9433V69.4688H68.596L68.5343 69.9006C67.7323 74.4657 66.5602 76.9025 65.0796 79Z"
      fill={color || '#FBF9F5'}
    />
  </svg>
);

export default MoneyBag;
